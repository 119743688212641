import type IStrapiGetStartedPage from '../interfaces/IStrapiGetStartedPage';
import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/Seo";
import Layout from "../components/Layout";
import FlexContentBannerAlt from '../query/blocks/FlexContentBannerAlt';
import FlexGetHiredFeatures from '../query/blocks/FlexGetHiredFeatures';
import FlexGetHiredCta from '../query/blocks/FlexGetHiredCta';
import FlexGetHiredBgBlock from '../query/blocks/FlexGetHiredBgBlock';

interface Props { 
  data: { 
    strapiGetStartedPage: IStrapiGetStartedPage
  }
}

const GetStartedPage = (props: Props) => {
  const { data: { strapiGetStartedPage } } = props;

  const {
    heroImage,
    heroImageMobile,
    heading,
    subheading,
    content,
    steps,
    ctaSubheading,
    ctaButtonText,
    ctaButtonUrl,
    ctaImage,
    ctaText,
    testemonial,
    seo
  } = strapiGetStartedPage;

  const { 
    title: seoTitle, 
    description: seoDescription,
    image: seoImage, 
    twitterImage: seoTwitterImage
  } = seo; 

  return (
    <Layout
      headerLocation="candidates"
    >
      <SEO
        title={seoTitle}
        description={seoDescription}
        url="/"
        image={
          seoImage?.localFile?.publicURL 
            ? seoImage?.localFile?.publicURL 
            : undefined
        }
        twitterImage={
          seoTwitterImage?.localFile?.publicURL 
            ? seoTwitterImage?.localFile?.publicURL 
            : undefined
        }
      />
      <FlexContentBannerAlt
        block={{ 
          backgroundImageDesktop: {
            imageFile: heroImage.localFile,
            altText: heroImage.alternativeText,
          },
          backgroundImageMobile: { 
            imageFile: heroImageMobile.localFile,
            altText: heroImageMobile.alternativeText,
          }
        }}
      />
      <FlexGetHiredFeatures
        block={{
          heading, 
          description: subheading,
          text: content,
          numbersWithDescription: steps.map((step, index) => ({
            id: index,
            number: `0${index + 1}`,
            description: step.text,
          }))
        }}
        divSubheading={true}
      />  
      <FlexGetHiredCta
        block={{
          heading: ctaSubheading,
          description: ctaText,
          image: { 
            imageFile: ctaImage.localFile,
            altText: ctaImage.alternativeText,
          },
          button: { 
            title: ctaButtonText,
            url: ctaButtonUrl,
          }
        }}
      />
      <FlexGetHiredBgBlock
        block={{
          testimonials: testemonial.map(testemonial => ({
            fullName: testemonial.name,
            testimonial: testemonial.quote,
            description: testemonial.position,
            image: { 
              imageFile: testemonial.image.localFile,
              altText: testemonial.image.alternativeText
            }
          }))
        }}
      />
    </Layout>
  );
};

export default GetStartedPage;

export const pageQuery = graphql`
  query {
    strapiGetStartedPage { 
      heroImage { 
        alternativeText
        localFile { 
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              breakpoints: [750, 1200]
              width: 800
              quality: 70
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
      heroImageMobile { 
        alternativeText
        localFile { 
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              breakpoints: [750, 1200]
              width: 1400
              quality: 70
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
      heading
      subheading
      content
      steps { 
        text
      }
      ctaImage {
        alternativeText
        localFile { 
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              breakpoints: [750, 1200]
              width: 500
              quality: 70
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
      ctaText
      ctaSubheading
      ctaButtonText
      ctaButtonUrl
      testemonial {
        image {
          alternativeText
          localFile { 
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                breakpoints: [750, 1200]
                width: 160
                quality: 60
                formats: [AUTO, WEBP]
                placeholder: NONE
              )
            }
          }
        }
        quote
        name
        position
      }
      seo { 
        title
        description 
        image {
          localFile {
            publicURL
          }
        }
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
    }    
  }
`;
